
const Configuration = {
    env: "prod",

    version: "v007 - 20240416",

    basename: "",
    
    webapp: {
        url: "https://widgets.forebase.com/",
        loginService: "login/"
    },

    logger: {
        enable: true,
        format: "[REQID] - [CMP]: [MSG]"
    },

    flexServerStudio: {
        url: "https://widgets.forebase.com/srv"
    },

    fexpressbackoffice: {
        url: "https://widgets.forebase.com/backoffice",
        loginService: "login"
    }
}

export default Configuration;